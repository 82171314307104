import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function CarCard({
  carBrand,
  carModel,
  carYear,
  carEngine,
  carFuel,
  carCountryFrom,
  carPrice,
  carImage,
  carCity,
  carFullClearance,
  carDiccountClearance,
}) {
  return (
    <div className="w-full rounded overflow-hidden shadow-md">
      {/* <StaticImage
          className="w-full"
          src="../i/alan-flack-VtfiPvSGh5s-unsplash.jpg"
          alt={`${carBrand} ${carModel}`}
        /> */}
      <GatsbyImage
        className="w-full"
        image={getImage(carImage)}
        alt={`${carBrand} ${carModel}`}
      />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">
          {`${carBrand} ${carModel}`}
        </div>
        <p className="text-gray-700 text-base mb-4">
          Авто {carYear} року з об'ємом двигуна {carEngine} см<sup>3</sup>,
          паливо - {carFuel.toLowerCase()}.
        </p>
        <ul className="list-none text-gray-700">
          <li>
            Повна ціна розмитнення:{" "}
            <span className="text-gray-900">{`${parseFloat(
              carFullClearance
            ).toLocaleString()} грн`}</span>
          </li>
          <li>
            Пільгова ціна:{" "}
            <span className="text-gray-900">{`${parseFloat(
              carDiccountClearance
            ).toLocaleString()} грн`}</span>
          </li>
        </ul>
      </div>
      <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {carCity}
        </span>
        {/* <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          #travel
        </span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          #winter
        </span> */}
      </div>
    </div>
  )
}

CarCard.defaultProps = {
  carBrand: "Volkswagen",
  carModel: "Jetta Mk6",
  carYear: "2015",
  carEngine: "1600",
  carFuel: "Бензин",
  carCountryFrom: "Польша",
  carPrice: "6050",
  carCity: "Київ",
  carFullClearance: "",
  carDiccountClearance: "",
}
