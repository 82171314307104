import React, { Component } from "react"
// import { BiEuro } from "react-icons/bi"

export default class CalcForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fuelType: "choose",
      carAge: "choose",
      engineVolume: "",
      ageCost: 0,
      engineCost: 0,
      fuelCost: 0,
      vatCost: 0,
      taxCost: 8500,
      totalCost: 0,
      EUR: 32.27,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })

    // console.log(event.target.name)
    // console.log(event.target.value)
    // console.log(this.state)
    setTimeout(() => {
      console.log(this.state)
    })
  }

  handleSubmit(event) {
    // alert("A name was submitted: " + this.state.value)
    event.preventDefault()
    // console.log(event.target.carAge.value)

    const CalculateTotal = () => {
      let fuelType = event.target.fuelType.value
      let carAge = event.target.carAge.value
      let engineVolume = event.target.engineVolume.value

      const getFuelCost = () => {
        if (fuelType === "diesel") {
          return this.setState({ fuelCost: +100 })
        } else {
          return this.setState({ fuelCost: +0 })
        }
      }

      const getAgeCost = () => {
        if (carAge === "9") {
          return this.setState({ ageCost: +0 })
        } else if (carAge === "10") {
          return this.setState({ ageCost: +25 })
        } else if (carAge === "11") {
          return this.setState({ ageCost: +50 })
        } else if (carAge === "12") {
          return this.setState({ ageCost: +75 })
        } else if (carAge === "13") {
          return this.setState({ ageCost: +100 })
        } else if (carAge === "14") {
          return this.setState({ ageCost: +125 })
        } else if (carAge === "15") {
          return this.setState({ ageCost: +150 })
        } else {
          return this.setState({ ageCost: "Error" })
        }
      }

      const getVolumeCost = () => {
        if (engineVolume > 990 && engineVolume <= 2000) {
          return this.setState({
            engineCost: parseInt(engineVolume, 10) * 0.25,
          })
        } else if (engineVolume > 2000 && engineVolume <= 3000) {
          return this.setState({
            engineCost: parseInt(engineVolume, 10) * 0.2,
          })
        } else if (engineVolume > 3000 && engineVolume <= 4000) {
          return this.setState({
            engineCost: parseInt(engineVolume, 10) * 0.25,
          })
        } else if (engineVolume > 4000 && engineVolume <= 5000) {
          return this.setState({
            engineCost: parseInt(engineVolume, 10) * 0.35,
          })
        } else if (engineVolume > 5000 && engineVolume <= 6000) {
          return this.setState({
            engineCost: parseInt(engineVolume, 10) * 0.5,
          })
        } else {
          return this.setState({ engineCost: "Error" })
        }
      }

      getFuelCost()
      getAgeCost()
      getVolumeCost()

      const getVatCost = () => {
        let vat = Math.round(
          0.2 *
            (parseInt(this.state.ageCost, 10) +
              parseInt(this.state.engineCost, 10) +
              parseInt(this.state.fuelCost, 10))
        )
        if (fuelType === "electro") {
          return this.setState({ vatCost: +0 })
        } else {
          return this.setState({
            vatCost: vat,
          })
        }
      }
      setTimeout(() => {
        getVatCost()
      })

      const getTotalCost = () => {
        let total =
          (parseInt(this.state.ageCost, 10) +
            parseInt(this.state.engineCost, 10) +
            parseInt(this.state.fuelCost, 10) +
            parseInt(this.state.vatCost, 10)) *
            parseInt(this.state.EUR, 10) +
          parseInt(this.state.taxCost, 10)
        this.setState({
          totalCost: total,
        })
        return console.log(total)
      }

      setTimeout(() => {
        getTotalCost()
      })
    }
    CalculateTotal()
    setTimeout(() => {
      console.log(this.state)
    })
  }

  render() {
    return (
      <div className="py-12">
        <div className="mt-8 grid md:grid-cols-2 sm:grid-cols-1 gap-x-6">
          <form className="grid gap-6" onSubmit={this.handleSubmit}>
            <label className="block">
              <span className="text-gray-700">Тип палива</span>
              <select
                name="fuelType"
                className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                value={this.state.fuelType}
                onChange={this.handleChange}
              >
                <option disabled value="choose">
                  Оберіть тип палива
                </option>
                <option value="petrol">Бензин</option>
                <option value="diesel">Дизель</option>
                <option value="hybrid">Гібрід</option>
                <option value="electro">Електро</option>
              </select>
            </label>
            <label className="block">
              <span className="text-gray-700">Вік авто</span>
              <select
                name="carAge"
                className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                value={this.state.carAge}
                onChange={this.handleChange}
              >
                <option disabled value="choose">
                  Оберіть вік автомобіля
                </option>
                <option value="15">15 або старше (+150 EUR)</option>
                <option value="14">14 років (+125 EUR)</option>
                <option value="13">13 років (+100 EUR)</option>
                <option value="12">12 років (+75 EUR)</option>
                <option value="11">11 років (+50 EUR)</option>
                <option value="10">10 років (+25 EUR)</option>
                <option value="9">9 або молодше (+0 EUR)</option>
              </select>
              {/* <span className="text-sm text-gray-400">від 1995 по 2016 рр</span> */}
            </label>

            <label className="block">
              <span className="text-gray-700">
                Об’єм двигуна, см<sup>3</sup>
              </span>
              <input
                name="engineVolume"
                type="number"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                placeholder="від 990 до 6000"
                value={this.state.engineVolume}
                onChange={this.handleChange}
              />
              {/* <span className="text-sm text-gray-400">від 990 до 6000</span> */}
            </label>

            <button type="submit" className="btn-my">
              Підрахувати
            </button>
          </form>
          <table className="table-auto md:mx-4 lg:mx-8 my-8 text-gray-700">
            <tbody className="">
              <tr>
                <td>Ставка за вік</td>
                <td
                  id="ageCost"
                  className="text-right"
                >{`${this.state.ageCost.toLocaleString()} EUR`}</td>
              </tr>
              <tr>
                <td>Ставка за об'єм двигуна</td>
                <td
                  id="engineCost"
                  className="text-right"
                >{`${this.state.engineCost.toLocaleString()} EUR`}</td>
              </tr>
              <tr>
                <td>Ставка за тип палива</td>
                <td
                  id="fuelCost"
                  className="text-right"
                >{`${this.state.fuelCost.toLocaleString()} EUR`}</td>
              </tr>
              <tr>
                <td>ПДВ</td>
                <td
                  id="vatCost"
                  className="text-right"
                >{`${this.state.vatCost.toLocaleString()} EUR`}</td>
              </tr>
              <tr>
                <td>Добровільний внесок</td>
                <td
                  id="taxCost"
                  className="text-right"
                >{`${this.state.taxCost.toLocaleString()} грн`}</td>
              </tr>
              <tr className="font-medium text-gray-900 text-lg">
                <td>Разом</td>
                <td
                  id="totalCost"
                  className="text-right"
                >{`${this.state.totalCost.toLocaleString()} грн`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
