import React from "react"
// import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Steps() {
  return (
    <StaticQuery
      query={graphql`
        query {
          step1: contentfulAsset(
            id: { eq: "8f6c0456-23b6-5931-8777-d7bcded582f1" }
          ) {
            id
            gatsbyImageData(aspectRatio: 1)
          }
          step2: contentfulAsset(
            id: { eq: "755500c1-7772-5a0f-8f88-ac78e40249be" }
          ) {
            id
            gatsbyImageData(aspectRatio: 1)
          }
          step3: contentfulAsset(
            id: { eq: "c573656f-77b5-5189-a1e7-d1fbb6881bd9" }
          ) {
            id
            gatsbyImageData(aspectRatio: 1)
          }
        }
      `}
      render={data => (
        <div className="flex flex-col justify-center m-auto">
          <div className="flex md:flex-row flex-col  justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full  border-red-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-red-100 bg-red-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2 z-10">
                1
              </div>
              <GatsbyImage
                alt="step1"
                className="w-56 h-56 rounded-full shadow my-5 object-scale-down"
                image={getImage(data.step1)}
              />
              {/* <img
                alt="step1"
                className="w-56 h-56 rounded-full shadow my-5 object-scale-down"
                src="https://image.flaticon.com/icons/svg/1330/1330216.svg"
              /> */}
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-red-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded">
              {/* <div className="text-xs uppercase font-bold text-gray-500">
            Крок 1
          </div> */}
              <div className="md:text-3xl text-xl font-bold text-gray-900">
                Зняття авто з обліку в країні реєстрації
              </div>
              <div className="mt-4 ">
                У більшості країн Європи допускається зняття автомобіля з обліку
                без особистої присутності. Але деякі країни вимагають надати
                зняті з авто номерні знаки. Надайте нам скан техпаспорта авто,
                після чого ми з'ясуємо процедуру зняття з обліку в країні
                реєстрації.
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col bg-gray-50 justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-red-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-pink-100 bg-red-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2 z-10">
                2
              </div>
              <GatsbyImage
                alt="step2"
                className="w-56 h-56 rounded-full shadow my-5 object-scale-down"
                image={getImage(data.step2)}
              />
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-red-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-gray-50">
              {/* <div className="text-xs uppercase font-bold text-red-500">Крок 2</div> */}
              <div className="md:text-3xl text-xl font-bold text-gray-900">
                Надання брокеру документів на авто
              </div>
              <div className="mt-4 ">
                Для митного оформлення будуть потрібні: паспорт (або посвідка на
                проживання), ІПН, технічний паспорт автомобіля, документ, що
                підтверджує право власності або розпорядження автомобілем,
                документ, що підтверджує зняття авто з обліку в країні
                реєстрації.
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col  justify-center md:text-left text-center">
            <div className="flex flex-col justify-center items-center relative">
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-r-4 border-red-300 border-dashed"></div>
              </div>
              <div className="rounded-full w-12 h-12 text-xl text-red-100 bg-red-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2 z-10">
                3
              </div>
              <GatsbyImage
                alt="step3"
                className="w-56 h-56 rounded-full shadow my-5 object-scale-down"
                image={getImage(data.step3)}
              />
              <div className="w-56 h-12 md:flex hidden justify-center">
                <div className="h-full border-rad-300 border-dashed"></div>
              </div>
            </div>
            <div className="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded ">
              {/* <div className="text-xs uppercase font-bold text-gray-500">
            Крок 3
          </div> */}
              <div className="md:text-3xl text-xl font-bold text-gray-900">
                Митне оформлення зі сплатою податків і штрафу
              </div>
              <div className="mt-4 ">
                Сплата добровільного внеску в розмірі 500 НМДГ (8500 грн)
                відповідно до п. 97 розділу XXI Митного Кодексу України звільняє
                громадян від адміністративної відповідальності за порушення
                термінів тимчасового ввезення/транзиту або передачу автомобіля в
                управління/користування іншій особі. Штраф 8500 грн набагато
                нижче штрафів за дані порушення митних правил.
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}
