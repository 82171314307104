import React from "react"
import FaqCard from "../components/FaqCard"

export default function Faq({ faqs }) {
  return (
    <div className="container-my">
      <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-900 mb-14">
        Поширені запитання
      </h2>

      <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
        {faqs.faq.map(f => (
          <FaqCard key={f.id} question={f.question} answer={f.answer.answer} />
        ))}
      </div>
    </div>
  )
}
