import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/global/Seo"
import Header from "../components/global/header"
import Footer from "../components/global/footer"
import CardCar from "../components/CarCard"
import Team from "../components/Team"
import Features from "../components/Features"
// import Testimonials from "../components/Testimonials"
// import Typo from "../components/typography"
import Faq from "../components/Faq"
import Steps from "../components/steps"
import Modal from "../components/Modal"
import ContactForm from "../components/FormContact"
import Calculator from "../components/Calculator"

export const query = graphql`
  query CarQuery {
    cars: allContentfulCar {
      nodes {
        id
        brand
        model
        year
        engineVolume
        fuel
        origin
        sellPrice
        customsClearancePrice
        discountClearancePrice
        image {
          gatsbyImageData(aspectRatio: 1.5)
        }
        city {
          name
        }
      }
    }
    faqs: contentfulService(
      id: { eq: "194997b7-d8d3-5731-a5f6-f372c77d33c6" }
    ) {
      id
      faq {
        id
        question
        answer {
          answer
        }
      }
    }
  }
`

export default function Home({ data: { cars, faqs } }) {
  return (
    <>
      <Seo
        title={`Митне оформлення автомобілів в Україні 2021`}
        description={`Брокери BNCONSULT. Митне оформлення авто 2021 ✓Безкоштовна консультація ✓Розмитнення під ключ ✓Документи, терміни​, ціни.`}
        image={``}
        url={"/"}
      />
      <Header />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 py-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <main className=" mx-auto max-w-7xl px-4 lg:px-8 ">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-sans font-bold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Пільгове оформлення</span>{" "}
                  <span className="block text-red-600 xl:inline">
                    Єврономерів
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Пільгове митне оформлення автомобілів на іноземних номерах за
                  {` `}
                  <span className="text-red-600 font-bold">1 день</span>, за
                  наявністю повного пакета документів.{` `}
                  <span className="text-red-600 font-bold">
                    Розмитнення євроблях
                  </span>{" "}
                  за спрощеною процедурою з 15 червня по 15 грудня 2021 року за
                  новими Законами України - 1402, 1403, 4643.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <Modal />
                  </div>
                  {/* <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 md:py-4 md:text-lg md:px-10"
                    >
                      Безкоштовна консультація
                    </a>
                  </div> */}
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../i/jenny-ueberberg-PEjIzb2bhAk-unsplash.jpg"
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="bg"
            layout="fullWidth"
            placeholder="tracedSVG"
            // transformOptions={}
            // src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
            loading="lazy"
            // formats={["AUTO", "WEBP", "AVIF"]}
            // aspectRatio={21 / 9}
          />
        </div>
      </div>
      <Features />
      <ContactForm />
      <div className="container-my car-examples">
        <h2 className="lg:text-center">Порівняйте ціни на митне оформлення</h2>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto lg:text-center">
          Для приклада наведені ціни на пільгове розмитнення поширених
          автомобілів в Україні. Інколи фінальна ціна може відрізнятися.
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          {cars.nodes.map(car => (
            <CardCar
              key={car.id}
              carBrand={car.brand}
              carModel={car.model}
              carYear={car.year}
              carEngine={car.engineVolume}
              carFuel={car.fuel}
              carCountryFrom={car.origin}
              carPrice={car.sellPrice}
              carImage={car.image}
              carCity={car.city.name}
              carFullClearance={car.customsClearancePrice}
              carDiccountClearance={car.discountClearancePrice}
            />
          ))}
        </div>
      </div>
      <div className="container-my">
        <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl lg:text-center">
          Калькулятор розмитнення "
          <span className="text-red-600">Євроблях</span>"
        </h2>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto lg:text-center">
          Митний калькулятор ціни пільгового оформлення автомобілів на іноземних
          номерах в 2021 році за новими Законами України - 1402, 1403, 4643.
        </p>
        <Calculator />
        {/* <ContactForm /> */}
      </div>
      <Faq faqs={faqs} />
      <Steps />
      {/* <div className="container-my">
        Калькулятор розмитнення https://calc.customs.gov.ua/
      </div> */}
      <Team />
      {/* <Testimonials /> */}
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Бажаєте дізнатися Більше?</span>
            <span className="block text-red-600">
              Залиште заявку для отримання консультації
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              {/* <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              >
                Безкоштовна консультація
              </a> */}
              <Modal buttonText="Безкоштовна консультація" />
            </div>
            {/* <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-white hover:bg-red-50"
              >
                Безкоштовна консультація
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
// export const indexPageQuery = graphql`
//   query HomePageQuery {
//     site {
//       siteMetadata {
//         description
//       }
//     }
//   }
// `
