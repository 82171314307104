import React from "react"
import { RiSendPlane2Line } from "react-icons/ri"

export default function FormConsult() {
  // const [validated, setValidated] = useState(false)

  // const handleSubmit = event => {
  //   const form = event.currentTarget
  //   if (form.checkValidity() === false) {
  //     event.preventDefault()
  //     event.stopPropagation()
  //   }
  //   setValidated(true)
  // }
  return (
    <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          {/* <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          /> */}
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Отримати консультацію
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
            Or{" "}
            <a
              href="#"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              start your 14-day free trial
            </a>
          </p> */}
        </div>
        <form
          data-netlify="true"
          netlify-honeypot="bot-field"
          name="Consultation"
          form-name="Consultation"
          method="POST"
          action="/dyakuyu/"
          id="consultModalForm"
          className="mt-8 space-y-6"
          // validated={validated}
          // onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="Consultation" />
          <input type="text" name="bot-field" className="hidden" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="tel" className="sr-only">
                Номер телефону
              </label>
              <input
                id="phone"
                name="tel"
                type="tel"
                autoComplete="tel"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 "
                placeholder="Ваш номер телефону"
              />
            </div>
            <div>
              <label htmlFor="name" className="sr-only">
                Ім'я
              </label>
              <input
                id="contactName"
                name="name"
                type="text"
                autoComplete="name"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 "
                placeholder="Ваше Ім'я"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                required
                id="is_agreed"
                name="is_agreed"
                type="checkbox"
                className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
              />
              <label
                htmlFor="is_agreed"
                className="ml-2 block text-sm text-gray-900"
              >
                Згода з умовами сайту
              </label>
            </div>

            {/* <div className="text-sm">
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div> */}
          </div>

          <div>
            <button
              type="submit"
              className="btn-my group relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <RiSendPlane2Line
                  className="h-5 w-5 text-yellow-50"
                  aria-hidden="true"
                />
                {/* <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                /> */}
              </span>
              Відправити
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
