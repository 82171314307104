import React from "react"
import { FaQuestion } from "react-icons/fa"

export default function FaqCard({ question, answer }) {
  return (
    <div className="bg-gray-50 text-gray-800 p-6 rounded-md w-full relative shadow-md">
      <h3 className="mb-4 font-bold">{question}</h3>
      <p className="">{answer}</p>
      <FaQuestion className="text-5xl absolute right-2 bottom-4 text-red-100" />
    </div>
  )
}
