import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MdPhone } from "react-icons/md"
import { SiTelegram, SiViber } from "react-icons/si"

export default function Team() {
  return (
    <StaticQuery
      query={graphql`
        query ManagerQuery {
          allContentfulManager {
            nodes {
              id
              name
              position
              slogan
              phone
              avatar {
                gatsbyImageData
              }
            }
          }
        }
      `}
      render={data => (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="mx-auto mb-10 lg:max-w-xl text-center">
            <h2 className="h2">Наша Команда</h2>
            <p className="text-xl text-gray-500 mt-4">
              Команда сертифікованих фахівців з підтвердженим допуском до митних
              операцій в Україні.
            </p>
          </div>
          <div className="grid gap-10 mx-auto lg:grid-cols-2 lg:max-w-screen-lg">
            {data.allContentfulManager.nodes.map(manager => (
              <div className="grid sm:grid-cols-3 mb-6" key={manager.id}>
                <div className="relative w-full h-48 max-h-full rounded-sm sm:h-auto">
                  <GatsbyImage
                    className="absolute object-cover w-full h-full rounded"
                    image={getImage(manager.avatar)}
                    alt={`фото ${manager.name}`}
                  />
                </div>
                <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
                  <p className="text-2xl font-bold">{manager.name}</p>
                  <p className="mb-4 text-gray-600">{manager.position}</p>
                  <p className="mb-4 tracking-wide text-gray-800">
                    {manager.slogan}
                  </p>
                  <div className="flex items-center space-x-3 text-4xl">
                    <a
                      href={`tel:${manager.phone.replace(/\D/g, "")}`}
                      className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                      <MdPhone />
                    </a>
                    <a
                      href={`viber://chat/?number=%2B${manager.phone.replace(
                        /\D/g,
                        ""
                      )}`}
                      className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                      <SiViber />
                    </a>
                    <a
                      href={`https://t.me/%2B${manager.phone.replace(
                        /\D/g,
                        ""
                      )}`}
                      className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <SiTelegram />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    />
  )
}
